import React from "react";
import Photo from "../../../assets/photo-test.jpg";
import SkillBar from "./SkillBar";

const Skills = () => {
  return (
    <div className="flex flex-col">
      <div className="flex">
        <img
          src={Photo}
          alt="Me"
          className="h-36 w-36 ml-16 object-cover rounded-full border-4 border-stone-900"
        />
        <h1 className="mt-12 text-5xl text-customSecondary font-bold ml-32">
          My skills
        </h1>
      </div>
      <div className="ml-6">
        <h2 className="text-gray-200 text-2xl font-bold mt-6">Development</h2>
        <div className="border-b border-customSecondary my-4 w-1/2"></div>
        <SkillBar value={70}>Python</SkillBar>
        <SkillBar value={85}>React</SkillBar>
        <SkillBar value={65}>C</SkillBar>
        <SkillBar value={74}>Django</SkillBar>
        <SkillBar value={50}>JS</SkillBar>

        <h2 className="text-gray-200 text-2xl font-bold mt-6">Security</h2>
        <div className="border-b border-customSecondary my-4 w-1/2"></div>
        <SkillBar value={40}>Network</SkillBar>
        <SkillBar value={76}>Web</SkillBar>
        <SkillBar value={30}>Cryptography</SkillBar>
        <h2 className="text-gray-200 text-2xl font-bold mt-6">Languages</h2>
        <div className="border-b border-customSecondary my-4 w-1/2"></div>
        <SkillBar value={100}>French</SkillBar>
        <SkillBar value={86}>English</SkillBar>
        <SkillBar value={20}>Mandarin</SkillBar>
      </div>
    </div>
  );
};
export default Skills;
