// Button.js
import React from 'react';

const Button = ({ children, colorClass, roundedClass }) => {
  const baseClasses = "text-white font-bold py-2 px-4 focus:outline-none";
  return (
    <button className={`${baseClasses} ${colorClass} ${roundedClass} mr-1`}>
      {children}
    </button>
  );
};

export default Button;
