import React from "react";
import TimeElement from "./TimeElement";
import experiencesData from "./Experiences.json"; // Ajustez le chemin selon la structure de votre projet

const Timeline = () => {
  // Nombre d'événements pour l'exemple, à remplacer par vos données
  return (
    <div className="flex flex-col items-center">
      {/* Cercle en haut de la timeline */}
      <div className="w-3 h-3 bg-gray-200 rounded-full mb-0"></div>
      <div className="w-1 h-48 bg-gray-200 mt-0 z-20 relative"></div>

      {Object.keys(experiencesData).map((name, index) => {
        const x = index % 2 ? " rounded-r" : "-ml-7 rounded-l";
        const textPos = index % 2 ? "flew-row" : "flex-row-reverse";
        return (
          <div
            index={index}
            className={`flex ${textPos} justif-center content-center`}
          >
            <div className=" grow w-96"></div>
            <div className="grow-0 w-1">
              <div className={`w-8 h-1 bg-gray-100 mt-0 z-10  ${x} `}></div>
              <div className="w-1 h-48 bg-gray-200 mt-0 z-20 relative"></div>
            </div>
            <div className=" grow w-96">
              <TimeElement>{experiencesData[name]}</TimeElement>
            </div>
            {/*<div className="text-gray-200 relative z-10 h-0 -mt-8">
              <TimeElement textPos={textPos}>
                {experiencesData[name]}
              </TimeElement>
        </div>*/}
          </div>
        );
      })}
      <div className="w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[20px] border-t-gray-200 mx-auto"></div>
    </div>
  );
};

export default Timeline;
