import "./App.css";
import Header from "./components/layout/Header";
import Home from "./components/screens/home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/layout/Footer";
import PageNotFound from "./components/screens/PageNotFound";
import "./styles/animations.css";
import ExperiencesHome from "./components/screens/experiences/ExperiencesHome";
import SkillsHome from "./components/screens/skills/SkillsHome";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Contacts from "./components/screens/contact/Contacts";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null; // Ce composant n'a pas besoin de rendre quoi que ce soit visuellement
}

function App() {
  return (
    <div className="bg-gradient-to-r flex flex-col from-black to-stone-800 via-stone-700 min-h-screen h-full">
      <Router>
        <ScrollToTop />
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/experiences" element={<ExperiencesHome />} />
            <Route path="/skills" element={<SkillsHome />} />
            <Route path="/contact" element={<Contacts />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
