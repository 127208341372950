import React, { useEffect, useRef, useState } from "react";

const SkillBar = ({ value, children }) => {
  const [animate, setAnimate] = useState(false);
  const barRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimate(true); // Déclenchez l'animation une fois visible
          }
        });
      },
      { threshold: 0.1 }
    );

    if (barRef.current) {
      observer.observe(barRef.current);
    }

    return () => {
      if (barRef.current) {
        observer.unobserve(barRef.current);
      }
    };
  }, []);

  // Assurez-vous que la valeur est entre 0 et 100
  const normalizedValue = Math.min(100, Math.max(0, value));

  return (
    <div className="flex ">
      <span className="text-gray-200 w-24">{children}</span>
      <div
        ref={barRef}
        className="w-1/2 mt-2 ml-5 bg-gray-200 rounded-full h-3 border-2 border-stone-900 shadow-xl overflow-hidden"
      >
        <div
          className="bg-customSecondary-dark h-3 rounded-full"
          style={{
            width: animate ? `${normalizedValue}%` : "0%",
            transition: animate ? "width 1s ease-in-out" : "none",
          }}
        ></div>
      </div>
    </div>
  );
};

export default SkillBar;
