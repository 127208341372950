import React from "react";
import Timeline from "./Timeline";

const ExperiencesHome = () => {
  return (
    <div className="flex-col flex  justify-center">
      <div className="h-screen justify-center flex flex-col items-center">
        <div className="absolute top-0 left-0 w-full h-full bg-[url('../assets/work-logo.webp')] bg-cover filter blur-sm flex justify-center items-center"></div>
        <div className="absolute inset-0 bg-black bg-opacity-50"> </div>

        <div className="z-10 p-5 text-center fade-in">
          <h1 className="text-gray-200 text-7xl font-bold -mt-36">
            Experiences
          </h1>
          <h2 className="text-gray-200 text-4xl font-bold mt-10 ">
            <span className="text-customSecondary outline-1">Studies</span> and{" "}
            <span className="text-customSecondary">jobs</span>
          </h2>
        </div>
      </div>

      <Timeline />
    </div>
  );
};
export default ExperiencesHome;
