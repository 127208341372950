import React from "react";
import Button from "../common/Button";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="p-4 flex justify-between items-center z-50 w-full top-0 font-rob bg-stone-900 bg-opacity-20 sticky">
      <h1 className="text-lg text-gray-300">
        <span>Guillaume </span>
        <span className="font-bold text-customSecondary">MONTFORT</span>
      </h1>
      <div>
        <Link to="/home">
          <Button
            colorClass="bg-transparent hover:text-customSecondary"
            roundedClass="rounded-l"
          >
            Home
          </Button>
        </Link>
        <Link to="/experiences">
          <Button colorClass="bg-transparent hover:text-customSecondary">
            Experiences
          </Button>
        </Link>
        <Link to="/skills">
          <Button colorClass="bg-transparent hover:text-customSecondary">
            Skills
          </Button>
        </Link>
        <Link to="/contact">
          <Button
            colorClass="bg-transparent hover:text-customSecondary"
            roundedClass="rounded-r"
          >
            Contact
          </Button>
        </Link>
      </div>
    </header>
  );
};

export default Header;
