import React from "react";
import Photo from "../../../assets/photo-test.jpg";
import PictureWebDev from "../../../assets/web-dev-picture.webp";
import ShopPicture from "../../../assets/shop-picture.webp";
import SecurityPicture from "../../../assets/security-picture.webp";
import Section from "./Section.js";

const Home = () => {
  return (
    <div className="flex flex-col justify-end items-center fade-in">
      <div className="flex justify-end h-5/6">
        <div className="flex flex-col justify-end flex-grow w-1/2 pl-40 pt-12 pb-12">
          <img
            src={Photo}
            alt="Me"
            className="h-96 w-96 object-cover rounded-full border-4 border-stone-900"
          />
        </div>
        <div className="w-1/2 self-center text-2xl px-24 pt-16">
          <span className="text-gray-300 font-bold">
            Hi ! I'm Guillaume MONTFORT,{" "}
          </span>
          <span className="font-bold text-customSecondary">Web Developer </span>
          <span className="text-gray-300 font-bold">and </span>
          <span className="font-bold text-customSecondary">
            Cybersecurity Engineer
          </span>
        </div>
      </div>
      <div>
        <span className="text-gray-300 font-bold text-4xl h-96">
          Let's developp your projects{" "}
          <span className="font-bold text-customSecondary">together</span>
        </span>
      </div>

      {/*second section*/}
      <Section>
        <div className="flex justify-end h-5/6 mt-24">
          <div className="px-8 pt-16">
            <h2 className="text-gray-300 font-bold text-4xl pb-10">
              <span className="text-gray-300 font-bold text-4xl h-96">
                Expertise and&nbsp;
              </span>
              <span className="font-bold text-customSecondary">
                Innovation{" "}
              </span>
              <span className="text-gray-300 font-bold text-4xl h-96">
                at your service
              </span>
            </h2>
            <div className="font-bold text-gray-300 text-2xl leading-relaxed">
              With deep expertise in cutting-edge technologies and a constant
              passion for innovation, I offer you development services that not
              only meet your needs, but anticipate them. From React to Node.js,
              AI to cloud solutions, embrace the future today.
            </div>
          </div>
          <img
            src={PictureWebDev}
            alt="Web dev"
            className="h-96 w-96 z-10 object-cover rounded-full border-4 border-stone-900 mr-8"
          />
        </div>
      </Section>

      {/*third section*/}
      <Section>
        <div className="flex justify-end h-5/6 mt-1 ">
          <img
            src={ShopPicture}
            alt="Web dev"
            className="h-96 w-96 object-cover rounded-full border-4 border-stone-900"
          />
          <div className="px-8 pt-16">
            <h2 className="text-gray-300 font-bold text-4xl pb-10">
              <span className="text-gray-300 font-bold text-4xl h-96">
                Transform Your Ideas into&nbsp;
              </span>
              <span className="font-bold text-customSecondary">Reality </span>
            </h2>
            <div className="font-bold z-10 text-gray-300 text-2xl leading-relaxed">
              You have a vision, we have the technological solution. As an
              experienced freelance developer, I transform your most daring
              ideas into tailor-made, efficient and intuitive web and mobile
              applications. Together, let’s bring your projects to life.
            </div>
          </div>
        </div>
      </Section>
      {/*forth section*/}
      <Section>
        <div className="flex justify-end h-5/6 mt-16">
          <div className="px-8 pt-16">
            <h2 className="text-gray-300 font-bold text-4xl pb-10">
              <span className="font-bold text-customSecondary">
                Safety&nbsp;
              </span>
              <span className="text-gray-300 font-bold text-4xl h-96">
                and&nbsp;
              </span>
              <span className="font-bold text-customSecondary">Security</span>
            </h2>
            <div className="font-bold text-gray-300 text-2xl leading-relaxed">
              As a Cybersecurity Engineer, I prioritize your digital safety
              above all. Implementing cutting-edge security measures, I ensure
              your projects are robust against threats, maintaining the highest
              standards of confidentiality and integrity. Your peace of mind is
              my top commitment.
            </div>
          </div>
          <img
            src={SecurityPicture}
            alt="Web dev"
            className="h-96 w-96 z-10 object-cover rounded-full border-4 border-stone-900 mr-8"
          />
        </div>
      </Section>
    </div>
  );
};

export default Home;
