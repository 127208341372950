import React, { useEffect, useRef, useState } from "react";

const Section = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Si l'élément est dans le viewport, déclencher l'animation
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Optionnel : vous pouvez arrêter d'observer l'élément après le déclenchement
          observer.unobserve(ref.current);
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.3, // Ajustez ce seuil selon vos besoins
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className={`${isVisible ? "fade-in" : "opacity-0"}`}>
      {children}
    </div>
  );
};

export default Section;
