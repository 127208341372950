import React from "react";

const Footer = () => {
  return (
    <footer className="w-full opacity-60 bg-stone-900 p-4 flex text-xs text-gray-300 mt-6">
      <span className="flex-grow">Contact : contact@guillaumemontfort.fr</span>
      <span className=" mx-auto flex justify-end">
        <p className="text-left">© 2024. all rights reserved. </p>
      </span>
    </footer>
  );
};

export default Footer;
