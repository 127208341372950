import React, { useState, useEffect } from "react";
import FleeingWord from "./FleeingWord"; // Assurez-vous que le chemin d'importation est correct

const WordCloud = () => {
  const words = [
    "JS",
    "Python",
    "Node",
    "CSS",
    "HTML",
    "Cybersecurity",
    "React",
    "Tailwind",
    "C",
    "C++",
    "Java",
    "Network",
    "Reverse Engineering",
    "Web design",
  ];

  return (
    <div className="">
      {words.map((word) => {
        const x = Math.floor(Math.random() * 350) + 750; // 384 est la taille en pixels de 96 en Tailwind (24rem)
        const y = Math.floor(Math.random() * 350) + 150;

        return (
          <FleeingWord initialPosition={{ x: x, y: y }}>{word}</FleeingWord>
        );
      })}
    </div>
  );
};

export default WordCloud;
