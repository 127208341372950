import React from "react";
import Pingouin from "../../assets/pingouin-not-happy.png";

const PageNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="text-gray-100 font-bold text-9xl">404</div>
      <div className="text-gray-100 font-bold">THIS PAGE DOES NOT EXIST</div>
      <img
        src={Pingouin}
        alt="pingouin"
        className="h-96 w-96 object-cover mr-8 z-50 pl-16"
        style={{ objectPosition: "center top" }}
      />
    </div>
  );
};
export default PageNotFound;
