import React, { useState, useEffect } from "react";

const FleeingWord = ({ children, initialPosition }) => {
  const [position, setPosition] = useState(
    initialPosition || { x: 200, y: 200 }
  );
  const size = 50; // Taille de la div

  // Générer un facteur aléatoire pour le décalage de phase et la vitesse de l'opacité
  const [randomPhase] = useState(Math.random() * 2 * Math.PI); // Phase aléatoire entre 0 et 2π
  const [randomSpeed] = useState(0.1 + Math.random() * 1.2); // Vitesse aléatoire entre 0.5 et 2

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition((currentPosition) => {
        const mousePos = { x: e.pageX, y: e.pageY };
        const dx = currentPosition.x - mousePos.x;
        const dy = currentPosition.y - mousePos.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < 100 + size / 2) {
          const escapeX = (dx / distance) * 100;
          const escapeY = (dy / distance) * 100;
          return {
            x: currentPosition.x + escapeX,
            y: currentPosition.y + escapeY,
          };
        } else {
          return currentPosition;
        }
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((currentPosition) => {
        const returnX =
          currentPosition.x + (initialPosition.x - currentPosition.x) * 0.05;
        const returnY =
          currentPosition.y + (initialPosition.y - currentPosition.y) * 0.05;
        return { x: returnX, y: returnY };
      });

      // Utiliser le facteur aléatoire pour le décalage de phase et la vitesse
      const now = Date.now() / 1000; // Convertir en secondes
      const opacityPhase = now * randomSpeed + randomPhase;
      const newOpacity = 0.8 + Math.sin(opacityPhase) / 2;
      setPosition((currentPosition) => ({
        ...currentPosition,
        opacity: newOpacity,
      }));
    }, 50);

    return () => clearInterval(interval);
  }, [initialPosition, randomPhase, randomSpeed]);

  return (
    <div
      className="rounded-full bg-transparent text-gray-200 font-bold text-2xl"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: "absolute",
        left: `${position.x - size / 2}px`,
        top: `${position.y - size / 2}px`,
        transition: "left 0.2s, top 0.2s, opacity 0.2s",
        opacity: position.opacity ?? 1,
      }}
    >
      {children}
    </div>
  );
};

export default FleeingWord;
