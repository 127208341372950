import React from "react";
import WordsCloud from "./WordsCloud";
import FleeingWords from "./FleeingWord";
import Skills from "./Skills";

const SkillsHome = () => {
  return (
    <div className="flex flex-col">
      <div className="flex overflow-hidden relative fade-in">
        <div className="w-1/2 self-center text-gray-200 flex items-center flex-col h-screen px-24 pt-16">
          <span className=" font-bold text-7xl mb-8 mt-24 text-customSecondary">
            Skills
          </span>
          <div className=" text-4xl text-justify font-bold">
            My <span className="text-customSecondary">competences</span> at your{" "}
            <span className="text-customSecondary">service</span>
          </div>
        </div>
        <WordsCloud></WordsCloud>
      </div>
      <div>
        <Skills />
      </div>
    </div>
  );
};
export default SkillsHome;
