import React from "react";
import { useEffect, useRef, useState } from "react";

const TimeElement = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Si l'élément est dans le viewport, déclencher l'animation
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Optionnel : vous pouvez arrêter d'observer l'élément après le déclenchement
          observer.unobserve(ref.current);
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.3, // Ajustez ce seuil selon vos besoins
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const imagePath = require(`../../../assets/${children.logo}`);
  return (
    <div
      ref={ref}
      className={`${
        isVisible ? "fade-in" : "opacity-0"
      } h-48 pl-24 -mt-10 pr-12 hover:shadow-2xl hover:border-2 border-stone-700 border-opacity-40 transform hover:-translate-y-1 transition-all duration-300 ease-in-out`}
    >
      <img
        className="h-10 border-2 rounded-full border-stone-900"
        src={imagePath}
        alt={children.title}
      />

      <h1 className="text-gray-200 font-bold">
        {children.date} : &nbsp;
        <span className="text-customSecondary">{children.title}</span>
      </h1>
      <div className="italic text-gray-200 text-sm">{children.position}</div>
    </div>
  );
};
export default TimeElement;
